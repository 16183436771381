import * as React from "react"
import styled from '@emotion/styled'
import Layout from '../../components/Layout/Layout';
import { Link } from 'gatsby';
import { PageTitleHeader, Breakpoints, blissfulColour } from '../../globalStyles';

const Container = styled.div`
  margin: 3rem auto;
  max-width: 960px;
  padding: 0 20px;
  text-align: center;
`

const BackButton = styled.button`
  padding: 15px 25px; 
  color: white;
  background-color: ${blissfulColour};
  border: none;
  text-align: center;
  border-radius: 15px;

  :hover {
    cursor: pointer; 
    filter: brightness(120%);
  }
`

const Thanks = () => {
  return(
  <Layout
    title="JazaakumAllahu Khayra for submitting your form!"
    noIndex={true}
  >
    <Container>
      <PageTitleHeader> JazakumAllahu Khayra for submitting your form!  </PageTitleHeader>

      <p> We look forward to working with you. You will receive a response with the full pickup address as well confirmation of your order within 24 hours.  </p>
      <p> Early Eid Mubarak to you and your family. </p>

      <Link to="/">
        <BackButton>
          ← Back Home
        </BackButton>
      </Link>

    </Container>
  
  </Layout>
  )
}

export default Thanks

